@import "~@o4c/css/src/mixins/elevations";

.errors-container {
   background-color: rgba(0, 0, 0, 0.2);
   position: absolute;
   color: black;
   
   top: 0;
   left: 0;
   padding: 24px 16px;
   display: flex;
   height: 100vh;
   width: 100%;
   box-sizing: border-box;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   .o4c.card {
      @include elevation3();
      .header i {
         color: var(--negative);
      }
      .main p {
         margin-top: 0;
      }
   }
   .error-details {
      margin-top: 24px;
      padding: 16px;
      background: var(--grey-lighter);
      border-radius: var(--card-border-radius);
   }
}
